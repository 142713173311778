import React from "react"
import SEO from "../components/seo"
import Video from "../components/video"

import Layout from "../components/layout"

import "../styles/styles.scss"
import { NewsList } from "../components/news"
import IndexHeader from "../components/index-header"

const IndexPage = ({ data }) => {

  const news = data.allStrapiArticle.edges.map(x => {
    return {
      id: x.node.id,
      content: x.node.content,
      author: x.node.author,
      title: x.node.title,
      creationTime: x.node.creationTime,
      image: x.node.image,
      category: x.node.articles_category?.name,
      tags: x.node.article_tags.map(t => t.name),
      minutes_read: x.node.minutes_read
    }
  })

  const videoURL = null;
  const headerTitle = 'Welcome to PolkadotCenter!';
  const headerSubtitle = 'Explore the latest news on Polkadot projects.';
  const headerButtonText = "Go to Directory";
  const headerButtonLink = "/projects";

  return (
    <Layout>
      <SEO title="Home" />
      <div className="mgt-medium column home-page">
        <IndexHeader title={headerTitle} subtitle={headerSubtitle}
          buttonLink={headerButtonLink} buttonText={headerButtonText} />
        {videoURL &&
          <Video
            videoSrcURL={videoURL}
            videoTitle="Polkadot"
          />}
        <NewsList contentClass="is-size-5" news={news} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`  
  query {
    allStrapiArticle(limit:6) {
      edges {
        node {
          id
          content
          author
          title
          creationTime
          minutes_read
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          articles_category {
            name
          }  
          article_tags{
            name
            slug
          }        
        }
      }
    }
    allStrapiArticleTag {
      edges {
        node {
          name
          slug
        }
      }
    }
    allStrapiArticlesCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
