import React from "react"
import mainImg from "../images/Frame.png"
import { Link } from 'gatsby'

function IndexHeader(props) {
    return (
        <div className="index-header">
            <div className="columns">
                <div className="column title-content is-narrow">
                    <div>
                        {props.title &&
                            <div className="title is-1">
                                {props.title}
                            </div>}
                        {props.subtitle &&
                            <div className="is-size-4 subtext mb-5">
                                {props.subtitle}
                            </div>}
                        <Link to={props.buttonLink}>
                            <button class="button is-primary is-large">{props.buttonText}</button>
                        </Link>
                    </div>
                </div>
                <div className="column">
                    <img src={mainImg}></img>
                </div>
            </div>
        </div>
    )
}

export default IndexHeader
